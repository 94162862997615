import { useEffect, useState } from 'react';

import { MAX_HEIGHT } from 'src/constants/sizes';

const useHeight = () => {
  const [containerHeight, setContainerHeight] = useState(MAX_HEIGHT);

  const setHeight = () => {
    setContainerHeight(MAX_HEIGHT);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setHeight();
    window.addEventListener('resize', setHeight);
    return () => window.removeEventListener('resize', setHeight);
  }, []);

  return containerHeight;
};

export default useHeight;
