import React from 'react';
import PropTypes from 'prop-types';

import useHeight from 'src/hooks/use-height';

import classes from './style.module.scss';

const Wrapper = ({ children, onClick }) => {
  const containerHeight = useHeight();
  return (
    <div onClick={onClick} className={classes.wrapper} style={{ height: containerHeight }}>
      {children}
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Wrapper;
