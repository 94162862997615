import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { load } from './store/app/actions';
import { Localization } from './modules/i18n';
import { configureStore } from './store';

import 'src/style/index.scss';

window.__DEV__ = !!process.env.REACT_APP_IS_DEV;

const App = (App) => {
  const { store, persistor } = configureStore();
  Localization.init();

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

  store.dispatch(load(persistor));
};

export default App;
