import { SET } from './types';

export const VARIANTS = {
  FIRST: 'pink',
  SECOND: 'blue',
};

export const initialState = {
  buttonTitle: '',
  autoSkip: '',
  headerSubTitle: '',
  headerMainTitle: '',
  pageClickMode: false,
  pageVariant: VARIANTS.FIRST,
  targetTitles: {
    title: '',
    text: ''
  }
};

export const remoteConfigState = (state = initialState, action) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload.cfg,
      };
    default:
      return state;
  }
};
