import Attribution from '@wowmaking/web2app';
import { createAction } from 'redux-actions';

import { ADJUST_LINK, ADJUST_TOKEN, } from 'src/constants/general';

import Analytics from 'src/modules/analytics';
import { init as initRemoteConfig } from '../remote-config/actions';

import { SET_LOADED, SET_LINK } from './types';

const setLoaded = createAction(SET_LOADED);
const setLink = createAction(SET_LINK);

export const load = () => (dispatch) => {
  Attribution.init();
  Attribution.trackAdjustImpression(ADJUST_TOKEN);

  return Analytics.init()
    .then(() => dispatch(initRemoteConfig()))
    .then((link) => dispatch(setLink({ link })))
    .then(() => dispatch(setLoaded()))
    .then(() => Analytics.trackEvent('page', 'loaded'));
};

export const downloadApp = () => {
  Analytics.trackEvent('install_app', 'click');

  const link = Attribution.createAdjustLink(ADJUST_LINK, ADJUST_TOKEN);

  window.location.href = link;
};
