import React, {useEffect, Suspense, lazy} from 'react';
import {useSelector} from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import {downloadApp} from '../store/app/actions';
import Analytics from '../modules/analytics';

import Loading from 'src/components/loader/';
import Wrapper from 'src/hoc/wrapper';

const Main = lazy(() => import('./main'));
const Target = lazy(() => import('./target'));

const Screen = () => {
  const { loaded } = useSelector((state) => state.app);
  const { autoSkip, pageClickMode } = useSelector((state) => state.remoteConfig);
  const pathName = window.location.pathname;
  const page = pathName.substr(1) || 'main';

  useEffect(() => {
    if (loaded && autoSkip) {
      setTimeout(() => {
        Analytics.trackEvent(`redirect-${page}`, 'action');
        downloadApp();
      }, autoSkip * 1000);
    }
  }, [loaded, autoSkip, page]);

  const handlePageClick = () => {
    if (pageClickMode) {
      Analytics.trackEvent(`page-${page}`, 'click');
      downloadApp();
    }
  };

  return (
    <Wrapper onClick={handlePageClick}>
      {!loaded && <Loading/>}
      <Suspense fallback={<Loading/>}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Main/>}/>
            <Route exact path="/target" element={<Target />}/>
            <Route path='*' element={<Main />} />
          </Routes>
        </Router>
      </Suspense>
    </Wrapper>
  );
};

export default Screen;
